// 引入request
import requests from '../../Utils/request'

// 新增日程提醒
export const addCalendar = (data) => requests({  
  url: '/api/calendarreminder/add',
  method: 'post',
  data: data
})

// 修改日程提醒
export const editCalendar = (data) => requests({  
  url: '/api/calendarreminder/edit',
  method: 'post',
  data: data
})

// 获取日程提醒
export const getCalendar = (params) => requests({
  url: `/api/calendarreminder/list${params}`,
  method: 'get',
  params
})
// 获取风险点列表
export const getRiskDetail= (id) => requests({
  url: `document/file/risk/detail?id=${id}`,
  method: 'get',
})
// 删除日常
export const delEveryday= (id) => requests({
  url: `api/calendarreminder/delete/${id}`,
  method: 'delete',
})