var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-schedule"},[_c('el-dialog',{attrs:{"title":_vm.info.type == 'add' ? '新增日程' : '修改日程',"center":"","show-close":false,"visible":_vm.dialogVisible,"width":"460px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"row-box"},[_c('p',{staticClass:"label"},[_c('span',[_vm._v("* ")]),_vm._v(" 日程内容： ")]),_c('el-input',{attrs:{"placeholder":"请输入日程内容"},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1),_c('div',{staticClass:"row-box"},[_c('p',{staticClass:"label label2"},[_c('span',[_vm._v("* ")]),_vm._v(" 选择时间： ")]),_c('div',{staticClass:"date-box"},[_c('el-date-picker',{attrs:{"clearable":false,"type":"date","placeholder":"选择日期"},model:{value:(_vm.dateVal),callback:function ($$v) {_vm.dateVal=$$v},expression:"dateVal"}}),_c('div',{staticClass:"time-box"},[_c('el-time-select',{attrs:{"placeholder":"起始时间","clearable":false,"picker-options":{
              start: '00:00',
              step: '00:30',
              end: '24:00',
              maxTime: _vm.endTime,
            }},model:{value:(_vm.startTime),callback:function ($$v) {_vm.startTime=$$v},expression:"startTime"}}),_c('el-time-select',{attrs:{"placeholder":"结束时间","clearable":false,"picker-options":{
              start: '00:00',
              step: '00:30',
              end: '24:00',
              minTime: _vm.startTime,
            }},model:{value:(_vm.endTime),callback:function ($$v) {_vm.endTime=$$v},expression:"endTime"}})],1)],1)]),_c('div',{staticClass:"row-box"},[_c('p',{staticClass:"label"},[_vm._v("备注：")]),_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2 },"placeholder":"请输入内容"},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1),_c('div',{staticClass:"btns-box"},[_c('button',{staticClass:"btn",on:{"click":_vm.cancel}},[_vm._v("取 消")]),_c('button',{staticClass:"btn2",on:{"click":_vm.submit}},[_vm._v("保 存")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }