<template>
  <div class="todo-tisting">
    <div class="left-box">
      <p class="title">日程详情</p>
      <template v-if="scheduleDetailsList.length != 0">
        <div
          class="schedule-card"
          @click="edit(item)"
          v-for="item in scheduleDetailsList"
          :key="item.name"
        >
          <span class="dot"></span>
          <div class="schedule-centent">
            <p class="centent-p-1" :title="item.content">
              {{ item.content }}
              <span @click="del(item,$event)">删除</span>
            </p>
            <p class="centent-p-2">
              {{ item.startTime }} 至 {{ item.endTime }}
            </p>
            <p class="centent-p-2">
              {{ item.remark }}
            </p>
          </div>
        </div>
      </template>
    </div>
    <div class="right-box">
      <div class="btn-box">
        <button class="btn" @click="add"><span>+</span> 新增日程</button>
      </div>
      <div class="filter-date-box">
        <el-date-picker
          v-if="dateType == '月'"
          v-model="filterInfo.month"
          type="month"
          @change="selectMonth"
          :clearable="false"
          placeholder="选择月"
        >
        </el-date-picker>
        <el-date-picker
          v-if="dateType == '周'"
          v-model="filterInfo.week"
          type="week"
          format="yyyy 第 WW 周"
          @change="selectWeek"
          :clearable="false"
          placeholder="选择周"
        >
        </el-date-picker>
        <p class="title" v-show="dateType == '周'">
          <!-- {{ paramsInfo[0] }} — {{ paramsInfo[1] }} -->
          {{ paramsInfo[0].split("-")[0] }} 年
          {{ paramsInfo[0].split("-")[1] }} 月
          {{ paramsInfo[0].split("-")[2] }} 日 —
          {{ paramsInfo[1].split("-")[0] }} 年
          {{ paramsInfo[1].split("-")[1] }} 月
          {{ paramsInfo[1].split("-")[2] }} 日
        </p>
        <p class="title" v-show="dateType == '月'">
          <!-- {{(paramsInfo[0].split('-'))[0]}} 年
          —
          {{(paramsInfo[0].split('-'))[1]}} 月 -->
          {{ $getDate(1, filterInfo.month).split("-")[0] }} 年 —
          {{ $getDate(1, filterInfo.month).split("-")[1] }} 月
        </p>
        <div class="label-box">
          <p
            :class="dateType == item2 ? 'in-date-p' : ''"
            @click="selectDateType(item2)"
            v-for="item2 in dateTypeList"
            :key="item2"
          >
            {{ item2 }}
          </p>
        </div>
      </div>
      <div class="week-box" v-show="dateType == '周'">
        <el-row class="head-row">
          <el-col :span="3">时间</el-col>
          <el-col :span="3" v-for="item3 in weekList" :key="item3.label">
            {{ item3.label }}&nbsp;&nbsp;{{ item3.showDate }}
          </el-col>
        </el-row>
        <el-row class="time-row">
          <el-col :span="3">
            <p v-for="item4 in timeList" :key="item4">
              {{ item4 }}
            </p>
          </el-col>
          <el-col :span="3" v-for="item5 in weekList" :key="item5.label">
            <div class="col-card" v-for="item6 in timeList" :key="item6">
              <div
                class="is-col-card"
                @click="
                  clickDate(scheduleInfo[`${item5.date} ${item6}`], item5.date)
                "
                v-if="scheduleInfo[`${item5.date} ${item6}`]"
              >
                <div
                  class="is-col-card2"
                  @mouseover="mouseOver(scheduleInfo[`${item5.date} ${item6}`])"
                  @mouseleave="mouseLeave"
                  :class="scheduleInfo[`${item5.date} ${item6}`].colColor"
                  :ref="`card${scheduleInfo[`${item5.date} ${item6}`].id}`"
                  :style="setSty(scheduleInfo[`${item5.date} ${item6}`])"
                >
                  <p>
                    {{ scheduleInfo[`${item5.date} ${item6}`].startTime }}
                    —
                    {{ scheduleInfo[`${item5.date} ${item6}`].endTime }}
                  </p>
                  <p>{{ scheduleInfo[`${item5.date} ${item6}`].content }}</p>
                  <div
                    class="schedule-details"
                    v-if="
                      scheduleDetailsShow ==
                      scheduleInfo[`${item5.date} ${item6}`].id
                    "
                  >
                    <div
                      class="card"
                      v-for="(item7, index7) in scheduleDetailsInfo"
                      :key="index7"
                    >
                      <div class="card-row">
                        <p class="label">时间：</p>
                        <p class="val">
                          {{ item7.startTime }} - {{ item7.endTime }}
                        </p>
                      </div>
                      <div class="card-row">
                        <p class="label">内容：</p>
                        <p class="val">{{ item7.content }}</p>
                      </div>
                      <div class="card-row">
                        <p class="label">备注：</p>
                        <p class="val val2">{{ item7.remark }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="month-box" v-show="dateType == '月'">
        <el-calendar v-model="filterInfo.month" :first-day-of-week="7">
          <template slot="dateCell" slot-scope="{ data }">
              <!-- :class="data.day == '2022-09-24' || data.day == '2022-09-25' ? 'is-card':''" -->
            <div
              @mouseover="mouseOver2(monthScheduleInfo[data.day],data.day)"
              @mouseleave="mouseLeave2"
              class="calendar-card"
              @click="clickMonth(data.day)"
            >
              <p class="date">
                {{ Number(data.day.split("-").slice(2).join("")) }}
              </p>
              <div
                class="month-schedule-box"
                v-if="monthScheduleInfo[data.day]"
              >
                <div
                  class="card"
                  v-for="(item7, index7) in monthScheduleInfo[data.day].calendarList"
                  :key="item7.id"
                >
                  <div
                    class="filter-card"
                    :class="index7 == 1 ? 'in-filter-card' : ''"
                    v-if="index7 < 2"
                  >
                    {{ setMonthTime(item7.startTime) }}
                    -
                    {{ setMonthTime(item7.endTime) }}
                    {{ item7.content }}
                  </div>
                </div>
                <p
                  class="more"
                  v-if="monthScheduleInfo[data.day].calendarList.length > 2"
                >
                  还有{{
                    monthScheduleInfo[data.day].calendarList.length - 2
                  }}项...
                </p>
                <div
                  class="schedule-details"
                  v-if="scheduleDetailsShow == monthScheduleInfo[data.day].date">
                  <div
                    class="card"
                    v-for="(item7, index7) in scheduleDetailsInfo"
                    :key="index7"
                  >
                    <div class="card-row">
                      <p class="label">时间：</p>
                      <p class="val">
                        {{ item7.startTime }} - {{ item7.endTime }}
                      </p>
                    </div>
                    <div class="card-row">
                      <p class="label">内容：</p>
                      <p class="val">{{ item7.content }}</p>
                    </div>
                    <div class="card-row">
                      <p class="label">备注：</p>
                      <p class="val val2">{{ item7.remark }}</p>
                    </div>
                  </div>
                </div>
              </div>
                <!-- <div
                  style="position: relative;color: red;margin-bottom: 10px;"
                  v-if="data.day == '2022-09-24' || data.day == '2022-09-25' ? 'is-card':''">
                  <div
                    class="conference-box"
                    v-if="(conferenceDate == '2022-09-24' && data.day == '2022-09-24') ||
                    (conferenceDate == '2022-09-25' && data.day == '2022-09-25')">
                    ∗ 第四届劳动法律师大会
                  </div>
                </div> -->
            </div>
          </template>
        </el-calendar>
      </div>
    </div>
    <addSchedule v-if="showDialog" :info="scheduleDiaInfo"></addSchedule>
  </div>
</template>

<script>
import { core } from "../../../config/pluginInit";
import addSchedule from "@/views/Dialog/addSchedule";
import { getCalendar, delEveryday } from "@/FackApi/api/calendarApi";
export default {
  name: "TodoListing",
  components: {
    addSchedule,
  },
  data() {
    return {
      scheduleDetailsList: [],
      showDialog: false,
      showConference: false,
      scheduleDiaInfo: {
        // 日程弹框数据
        type: "add",
      },
      filterInfo: {
        // 时间选择条件
        month: new Date(),
        week: new Date(),
      },
      weekList: [], // 选择周的日期范围
      dateTypeList: ["月", "周"],
      dateType: "周", // 选择的日期类型
      paramsInfo: [], // 过滤的请求参数
      timeList: [
        // 周时间段数组
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
      ],
      scheduleInfo: {}, // 周日程数据
      monthScheduleInfo: {}, // 月日程数据
      scheduleDetailsShow: false, // 日程鼠标移入详情弹框
      scheduleDetailsInfo: [], // 鼠标移入弹框数据
      conferenceDate: '', // 律师大会时间
    };
  },
  created() {
    this.init();
  },
  mounted() {
    core.index();
  },
  methods: {
    init() {
      this.timeList = [];
      this.scheduleInfo = {};
      this.monthScheduleInfo = {};
      this.weekList = [];
      this.scheduleDetailsList = [];
      let weekList = ["日", "一", "二", "三", "四", "五", "六"];
      let newWeek = this.$getDate("week", this.filterInfo.week);
      let weekIdx = weekList.findIndex((item) => {
        return item == newWeek;
      });
      let weekIdx2 = 7 - weekIdx;
      let is_date = this.filterInfo.week.getTime();
      for (var i = 0; i < weekIdx; i++) {
        is_date -= 1000 * 60 * 60 * 24;
        this.weekList.unshift({
          label: "周" + this.$getDate("week", is_date),
          date: this.$getDate(1, is_date),
          showDate: this.$getDate(4, is_date),
        });
      }
      is_date = this.filterInfo.week.getTime();
      this.weekList.push({
        label: "周" + this.$getDate("week", is_date),
        date: this.$getDate(1, is_date),
        showDate: this.$getDate(4, is_date),
      });
      for (var i = 1; i < weekIdx2; i++) {
        is_date += 1000 * 60 * 60 * 24;
        this.weekList.push({
          label: "周" + this.$getDate("week", is_date),
          date: this.$getDate(1, is_date),
          showDate: this.$getDate(4, is_date),
        });
      }
      // console.log(this.weekList);
      this.paramsInfo = [
        this.weekList[0].date,
        this.weekList[this.weekList.length - 1].date,
      ];
      this.getCalendarInfo();
    },
    async getCalendarInfo() {
      // this.scheduleInfo = {};
      this.monthScheduleInfo = {};
      this.scheduleDetailsInfo = [];
      // console.log(this.paramsInfo);
      let res = await getCalendar(
        `/${this.paramsInfo[0]}/${this.paramsInfo[1]}`
      );
      // console.log(res);
      if (res.code == 0) {
        let data = res.items;
        if (this.dateType == "周") {
          let dawn = 0;
          data.forEach((item) => {
            if (item.calendarList) {
              item.calendarList.forEach((item2, index) => {
                let numList1 = item2.startTime.split(":");
                if (numList1[0].length == 1) {
                  item2.startTime = `0${numList1[0]}:${numList1[1]}`;
                }
                let startIdx = Number(numList1[0]) * 2;
                if (Number(numList1[1]) == 30) startIdx += 1;
                let numList2 = item2.endTime.split(":");
                if (numList2[0].length == 1) {
                  item2.endTime = `0${numList2[0]}:${numList2[1]}`;
                }
                let endIdx = Number(numList2[0]) * 2;
                if (Number(numList2[1]) == 30) endIdx += 1;
                let num3 = endIdx - startIdx;
                let keys = `${item.date} ${item2.startTime}`;
                if (Number(numList2[0]) < 8 && dawn == 0) {
                  dawn = 1;
                }
                this.$set(this.scheduleInfo, keys, item2); // 动态添加元素，避免页面不更新
                // console.log(num3);
                this.scheduleInfo[keys].colNum = num3 + 1; // 时间段占据的格子数
                this.scheduleInfo[keys].colColor = ""; // 设置隔行换色
                let len = Number(index) + 1;
                if (len % 2 == 0) {
                  this.scheduleInfo[keys].colColor = "green";
                }
              });
            }
            // console.log(this.scheduleInfo);
          });
          if (dawn == 1) {
            let dawnDate = [
              "00:00",
              "00:30",
              "01:00",
              "01:30",
              "02:00",
              "02:30",
              "03:00",
              "03:30",
              "04:00",
              "04:30",
              "05:00",
              "05:30",
              "06:00",
              "06:30",
              "07:00",
              "07:30",
              "08:00",
              "08:30",
              "09:00",
              "09:30",
              "10:00",
              "10:30",
              "11:00",
              "11:30",
              "12:00",
              "12:30",
              "13:00",
              "13:30",
              "14:00",
              "14:30",
              "15:00",
              "15:30",
              "16:00",
              "16:30",
              "17:00",
              "17:30",
              "18:00",
              "18:30",
              "19:00",
              "19:30",
              "20:00",
              "20:30",
              "21:00",
              "21:30",
              "22:00",
              "22:30",
              "23:00",
              "23::30",
              "24:00"
            ];
            this.timeList = dawnDate
              // .concat(this.timeList)
              // .concat(["22:30", "23:00", "23::30", "24:00"]);
          } else {
            this.timeList = [
              "08:00",
              "08:30",
              "09:00",
              "09:30",
              "10:00",
              "10:30",
              "11:00",
              "11:30",
              "12:00",
              "12:30",
              "13:00",
              "13:30",
              "14:00",
              "14:30",
              "15:00",
              "15:30",
              "16:00",
              "16:30",
              "17:00",
              "17:30",
              "18:00",
              "18:30",
              "19:00",
              "19:30",
              "20:00",
              "20:30",
              "21:00",
              "21:30",
              "22:00",
            ];
          }
        } else {
          data.forEach((item) => {
            // let numList = item.startTime.split(":");
            // if (numList[0].length == 1) {
            //   ite.startTime = `0${numList[0]}:${numList[1]}`;
            // }
            // console.log(item.calendarList);
            item.calendarList.map(item2 => {
              let num = item2.startTime.split(':');
              item2.num = Number(`${num[0]}${num[1]}`);
            })
            item.calendarList.sort((a,b) => {
              return a.num - b.num;
            })
            let keys = item.date;
            this.$set(this.monthScheduleInfo, keys, item);
          });
          // console.log(this.monthScheduleInfo);
        }
      }
    },
    // 选择月份
    selectMonth() {
      // console.log(this.filterInfo.month);
      this.setMonthDateNum();
    },
    // 选择周
    selectWeek() {
      this.weekList = [];
      this.scheduleInfo = {};
      let is_date = this.filterInfo.week.getTime();
      let datMmsec = 1000 * 60 * 60 * 24;
      this.weekList = [
        {
          label: "周日",
          date: this.$getDate(1, is_date - datMmsec),
          showDate: this.$getDate(4, is_date - datMmsec),
        },
        {
          label: "周一",
          date: this.$getDate(1, is_date),
          showDate: this.$getDate(4, is_date),
        },
      ];
      for (let i = 0; i < 5; i++) {
        is_date += 1000 * 60 * 60 * 24;
        this.weekList.push({
          label: "周" + this.$getDate("week", is_date),
          date: this.$getDate(1, is_date),
          showDate: this.$getDate(4, is_date),
        });
      }
      this.paramsInfo = [
        this.weekList[0].date,
        this.weekList[this.weekList.length - 1].date,
      ];
      this.getCalendarInfo();
    },
    // 选择时间类型
    selectDateType(val) {
      this.scheduleDetailsList = [];
      this.dateType = val;
      if (val == "月" && JSON.stringify(this.monthScheduleInfo) == "{}") {
        this.setMonthDateNum();
      }
      if (val == "周") {
        // this.paramsInfo = [
        //   this.weekList[0].date,
        //   this.weekList[this.weekList.length - 1].date,
        // ];
        this.init();
      } else {
        let newDateList = this.$getDate(1, this.filterInfo.month).split("-");
        let days = new Date(
          Number(newDateList[0]),
          Number(newDateList[1]),
          0
        ).getDate();
        this.paramsInfo = [
          `${newDateList[0]}-${newDateList[1]}-01`,
          `${newDateList[0]}-${newDateList[1]}-${days}`,
        ];
      }
    },
    // 获取当前月天数
    setMonthDateNum() {
      // console.log(this.filterInfo.month);
      let newDateList = this.$getDate(1, this.filterInfo.month).split("-");
      let days = new Date(
        Number(newDateList[0]),
        Number(newDateList[1]),
        0
      ).getDate();
      this.paramsInfo = [
        `${newDateList[0]}-${newDateList[1]}-01`,
        `${newDateList[0]}-${newDateList[1]}-${days}`,
      ];
      this.getCalendarInfo();
    },
    // 新增日程
    add() {
      this.scheduleDiaInfo.type = "add";
      this.showDialog = true;
    },
    edit(item) {
      this.scheduleDiaInfo.type = "edit";
      this.scheduleDiaInfo.item = item;
      this.showDialog = true;
    },
    // 点击周日程
    clickDate(info, date) {
      info.date = date;
      this.scheduleDetailsList = [info];
    },
    // 设置样式
    setSty(info) {
      return `height: ${info.colNum * 66}px;`;
    },
    // 鼠标移入显示弹框
    mouseOver(info) {
      this.scheduleDetailsShow = info.id;
      this.scheduleDetailsInfo = [info];
    },
    mouseOver2(info, date) {
      // console.log(date);
      this.conferenceDate = date;
      if (!info) return;
        this.scheduleDetailsShow = info.date;
        this.scheduleDetailsInfo = info.calendarList;
    },
    mouseOver3(date) {
    },
    // 鼠标移出
    mouseLeave() {
      this.scheduleDetailsShow = "";
    },
    mouseLeave2() {
      this.conferenceDate = '';
      this.scheduleDetailsShow = "";
    },
    mouseLeave3() {
    },
    // 间段显示
    setMonthTime(time) {
      let val = time;
      let numList = time.split(":");
      if (numList[0].length == 1) {
        val = `0${numList[0]}:${numList[1]}`;
      }
      return val;
    },
    // 点击月日程
    clickMonth(date) {
      if (this.monthScheduleInfo[date]) {
        this.monthScheduleInfo[date].calendarList.map((item) => {
          let numList = item.startTime.split(":");
          let numList2 = item.endTime.split(":");
          item.date = date;
          if (numList[0].length == 1) {
            item.startTime = `0${numList[0]}:${numList[1]}`;
          }
          if (numList2[0].length == 1) {
            item.endTime = `0${numList2[0]}:${numList2[1]}`;
          }
        });
        this.scheduleDetailsList = this.monthScheduleInfo[date].calendarList;
      } else {
        this.scheduleDetailsList = [];
      }
    },
    // 删除日常
    async del(row,e) {
      e.stopPropagation();
      let res = await delEveryday(row.id);
      // console.log(res);
      if (res.code == 0) {
        this.init();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.todo-tisting {
  display: flex;
  justify-content: space-between;
  margin: 20px 15px;
  margin-bottom: 30px;
  .left-box {
    width: 25%;
    border-radius: 18px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    padding: 16px;
    box-sizing: border-box;
    .title {
      font-size: 14px;
      color: #333;
    }
    .schedule-card {
      display: flex;
      margin-top: 15px;
      cursor: pointer;
      .dot {
        width: 10px;
        height: 10px;
        line-height: 20px;
        border-radius: 5px;
        margin: 7px 8px 0 0;
        background: #8279f3;
      }
      .schedule-centent {
        width: 94%;
        p {
          font-size: 14px;
          line-height: 24px;
        }
        .centent-p-1 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          color: #333;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          span {
            font-size: 12px;
            color: #6999f6!important;
            cursor:pointer;
          }
        }
      }
    }
  }
  .right-box {
    width: 73%;
    // min-height: 800px;
    border-radius: 18px;
    background-color: #fff;
    box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
    padding: 16px;
    box-sizing: border-box;
    .btn-box {
      display: flex;
      justify-content: flex-end;
      .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        height: 40px;
        background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
        border-radius: 10px;
        font-size: 14px;
        color: #fff;
        border: none;
        span {
          font-size: 24px;
          font-weight: 600;
          margin: 0 6px 4px 0;
        }
      }
    }
    /deep/ .filter-date-box {
      display: flex;
      align-items: center;
      margin: 15px 0;
      .el-date-editor {
        width: 200px;
        height: 40px;
        margin-right: 10px;
        cursor: pointer;
        .el-input__inner {
          height: 100%;
          border-radius: 20px;
          padding-right: 2px;
        }
      }
      .title {
        flex: 1;
        text-align: center;
        color: #333;
        font-weight: 600;
      }
      .label-box {
        display: flex;
        width: 140px;
        height: 40px;
        border-radius: 20px;
        overflow: hidden;
        p {
          width: 50%;
          line-height: 40px;
          text-align: center;
          color: #6474c7;
          background-color: #dce4ff;
          cursor: pointer;
        }
        .in-date-p {
          color: #fff;
          background: #6474c7;
        }
      }
    }

    .week-box {
      border: 1px solid #eaeaea;
      border-left: none;
      .head-row {
        padding-right: 8px;
        .el-col {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 36px;
          border-left: 1px solid #eaeaea;
        }
      }
      .time-row {
        height: 585px;
        overflow-y: auto;
        .el-col {
          p,
          .col-card {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 66px;
            border-top: 1px solid #eaeaea;
            border-left: 1px solid #eaeaea;
            .is-col-card {
              position: relative;
              width: 100%;
              height: 100%;
              .is-col-card2 {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: #f2f1fd;
                border-left: 5px solid #8279f3;
                padding-top: 2px;
                padding-left: 10px;
                cursor: pointer;
                p {
                  justify-content: initial;
                  height: initial;
                  font-size: 12px;
                  color: #333;
                  text-align: left;
                  border: none;
                }
              }
              .green {
                background-color: #eef9f1;
                border-left: 5px solid #29b245;
              }
            }
          }
        }
      }
    }

    .month-box {
      overflow: hidden;
      /deep/ .el-calendar {
        margin-top: -78px;
        .el-calendar__body {
          height: 635px;
          overflow-y: auto;
          .el-calendar-table {
            thead {
              th {
                text-align: center;
                border-top: 1px solid #eaeaea;
                border-left: 1px solid #eaeaea;
              }
              th::before {
                content: "周";
              }
            }
            thead th:last-child {
              border-right: 1px solid #eaeaea;
            }
            tbody {
              .el-calendar-table__row {
                td {
                  height: 120px;
                }
                .next,
                .prev {
                  .el-calendar-day {
                    height: 100%;
                  }
                  .date {
                    text-align: right;
                    margin-right: 10px;
                  }
                }
                .current {
                  .el-calendar-day {
                    height: 100%;
                    color: #333;
                    padding: 0;
                    .calendar-card {
                      width: 100%;
                      height: 100%;
                      .date {
                        text-align: right;
                        margin-right: 10px;
                      }
                      .month-schedule-box {
                        position: relative;
                        .card {
                          .filter-card {
                            width: 100%;
                            height: 32px;
                            line-height: 32px;
                            font-size: 12px;
                            background-color: #f2f1fd;
                            border-left: 4px solid #8279f3;
                            margin-top: 2px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            padding: 0 2px;
                            box-sizing: border-box;
                          }
                          .in-filter-card {
                            background-color: #eef9f1;
                            border-left: 4px solid #29b245;
                          }
                        }
                        .more {
                          padding-left: 6px;
                          font-size: 12px;
                          color: #909399;
                        }
                      }
                    }
                    .is-card {
                      background-color: red;
                      .date {
                        color: #fff;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .schedule-details {
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 180px;
    min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 10px;
    z-index: 9;
    padding: 10px;
    box-shadow: 0px 5px 5px 2px rgba(181, 181, 181, 0.3);
    .card {
      margin-bottom: 16px;
      .card-row {
        display: flex;
        align-items: flex-start !important;
        .label {
          width: 50px !important;
          color: #333;
        }
        .val {
          color: #333;
        }
        .val2 {
          display: block !important;
          width: 150px !important;
          overflow: hidden !important;
          white-space: nowrap !important;
          text-overflow: ellipsis !important;
        }
      }
    }
  }
  .conference-box {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(-50%, 0);
    height: 130px;
    width: 180px;
    color: #333;
    background-color: #fff;
    padding-top: 5px;
    border-radius: 10px;
    box-shadow: 0px 5px 5px 2px rgba(181, 181, 181, 0.3);
    z-index: 8;
  }
}
</style>

