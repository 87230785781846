<template>
  <div class="add-schedule">
    <el-dialog
      :title="info.type == 'add' ? '新增日程' : '修改日程'"
      center
      :show-close="false"
      :visible.sync="dialogVisible"
      width="460px"
      :before-close="handleClose"
    >
      <div class="row-box">
        <p class="label">
          <span>* </span>
          日程内容：
        </p>
        <el-input
          placeholder="请输入日程内容"
          v-model="form.content"
        ></el-input>
      </div>
      <div class="row-box">
        <p class="label label2">
          <span>* </span>
          选择时间：
        </p>
        <div class="date-box">
          <el-date-picker
            v-model="dateVal"
            :clearable="false"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
          <div class="time-box">
            <el-time-select
              placeholder="起始时间"
              v-model="startTime"
              :clearable="false"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00',
                maxTime: endTime,
              }"
            >
            </el-time-select>
            <el-time-select
              placeholder="结束时间"
              v-model="endTime"
              :clearable="false"
              :picker-options="{
                start: '00:00',
                step: '00:30',
                end: '24:00',
                minTime: startTime,
              }"
            >
            </el-time-select>
          </div>
        </div>
      </div>
      <div class="row-box">
        <p class="label">备注：</p>
        <!-- <el-input placeholder="请输入备注" v-model="form.remark"></el-input> -->
        <el-input
          type="textarea"
          :autosize="{ minRows: 2 }"
          placeholder="请输入内容"
          v-model="form.remark"
        >
        </el-input>
      </div>
      <div class="btns-box">
        <button class="btn" @click="cancel">取 消</button>
        <button class="btn2" @click="submit">保 存</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { addCalendar, editCalendar } from '@/FackApi/api/calendarApi'
export default {
  name: "addSchedule",
  components: {},
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      dialogVisible: true,
      form: {
        content: "",
        startTime: "",
        endTime: "",
        remark: "",
      },
      dateVal: "",
      startTime: '',
      endTime: '',
      throttleNum: 0,
      flag: null, // 计时器
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    init() {
      // console.log(this.info);
      if (this.info.type == "edit") {
        this.form = {
          content: this.info.item.content,
          startTime: this.info.item.startTime,
          endTime: this.info.item.endTime,
          id: this.info.item.id,
          remark: this.info.item.remark,
        };
        this.dateVal = this.info.item.date;
        this.startTime = this.info.item.startTime;
        this.endTime = this.info.item.endTime;
      }
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          // done();
          this.$parent.showDialog = false;
        })
        .catch((_) => {});
    },
    async submit() {
      if (this.throttleNum != 0) return;
      this.throttleNum = 1;
      let that = this;
      this.flag = setInterval(()=>{
        that.throttleNum ++;
        if (that.throttleNum == 2) {
          that.throttleNum = 0;
          clearInterval(this.flag);
        }
      },1000)
      if (!this.form.content) {
        this.$message.warning(`日程内容不能为空！`);
        return;
      }
      if (!this.dateVal || !this.startTime || !this.endTime) {
        this.$message.warning(`请完整选择日程时间！`);
        return;
      }
      let date = this.$getDate(1,this.dateVal);
      let params = {
        content: this.form.content,
        startTime: `${date} ${this.startTime}:00`,
        endTime: `${date} ${this.endTime}:00`,
        remark: this.form.remark,
      };
      if (this.info.type == 'edit') {
        params.id = this.form.id;
      }
      let res = null;
      if (this.info.type == 'add') {
        res = await addCalendar(params);
      } else {
        res = await editCalendar(params);
      }
      if (res.code == 0) {
        this.$parent.getCalendarInfo();
        this.cancel();
      }
    },
    cancel() {
      this.$parent.showDialog = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.flag);
  },
};
</script>

<style lang="less" scoped>
.add-schedule {
  /deep/ .el-dialog__wrapper {
    .el-dialog {
      border-radius: 20px;
      overflow: hidden;
      .el-dialog__header {
        height: 60px;
        line-height: 60px;
        font-size: 22px;
        font-weight: bold;
        color: #000;
        border-bottom: 1px solid #e7e7e7;
        padding: 0;
        background: #eaf5ff;
        span {
          color: #6474c7;
        }
      }
      .el-dialog__body {
        padding: 30px;
        .row-box {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          .label {
            min-width: 72px;
            font-size: 14px;
            color: #606266;
            margin-right: 18px;
            white-space: nowrap;
            span {
              color: red;
            }
          }
          .label2 {
            margin-right: 10px;
          }
          .el-input {
            .el-input__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda;
            }
          }
          .date-box {
            flex: 1;
            .el-date-editor {
              width: 100%;
              .el-input__inner {
                cursor: pointer;
              }
            }
            .time-box {
              display: flex;
              justify-content: space-between;
              width: 100%;
              margin-top: 10px;
              .el-date-editor {
                width: 48%;
              }
            }
          }
          .el-textarea {
            .el-textarea__inner {
              border-radius: 10px;
              border: 1px solid #d7dbda !important;
            }
          }
        }
        .btns-box {
          display: flex;
          justify-content: flex-end;
          button {
            width: 110px;
            height: 40px;
            font-size: 14px;
            font-weight: 600;
            color: #bbb;
            background: #f1f2f1;
            border-radius: 23px;
            border: none;
            margin-left: 20px;
            cursor: pointer;
          }
          .btn2 {
            color: #fff;
            background: linear-gradient(90deg, #6878c8 0%, #79baf2 100%);
            border: none;
          }
        }
      }
    }
  }
}
</style>
